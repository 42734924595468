import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { authInterceptor } from '@web/services';
import { appRoutes } from './app.routes';
import { fbAuthProvider } from './core/providers/fb-auth.provider';
import { appInitProvider } from './core/providers/app-init.provider';
import { appConfigProvider } from './core/providers/app-config.provider';

function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([ authInterceptor ])),
    provideAnimations(),
    appConfigProvider,
    appInitProvider,
    fbAuthProvider,
    provideCharts(withDefaultRegisterables()),

    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [ HttpClient ],
        },
      }),
    ),
  ],
};
