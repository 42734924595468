import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import itLocale from '@angular/common/locales/it';

import { authStore } from '@web/store';
import { FeatureFlagsService, LanguagesService } from '@web/services';
import { MatIconsService } from '../services/mat-icons.service';

export const appInitProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: () => {
    const auth = inject(authStore);
    const iconsService = inject(MatIconsService);
    const languagesService = inject(LanguagesService);
    const featureFlagsService = inject(FeatureFlagsService);

    return async (): Promise<void> => {
      await auth.checkAuth();
      await featureFlagsService.loadConfig();
      iconsService.registerCustomIcons();
      languagesService.setDefaultLanguage();
      registerLocaleData(itLocale);
    };
  },
  multi: true,
};
