import { bootstrapApplication } from '@angular/platform-browser';
import { register as registerSwiperElement } from 'swiper/element/bundle';

import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

// Register Swiper custom elements. We do this
// before bootstrapping the Angular application
// so that they're available before any part of
// our application tries rendering them.
registerSwiperElement();
bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
