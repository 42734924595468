import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MatIconsService {
  private matIconRegistry = inject(MatIconRegistry);
  private domSanitizer = inject(DomSanitizer);

  private basePath = 'assets/icons/';
  private loginPath = this.basePath + 'login/';
  private topBarPath = this.basePath + 'top-bar/';
  private campaignsPath = this.basePath + 'campaigns/';
  private profilePath = this.basePath + 'profile/';
  private postsPath = this.basePath + 'posts/';
  private socPath = this.basePath + 'soc-medias/';
  private createCampaignPath = this.basePath + 'create-campaign/';
  private createCampaignInterestsPath = this.basePath + 'create-campaign/interests/';
  private commonPath = this.basePath + 'common/';
  private paypalDialogPath = this.basePath + 'paypal-dialog/';

  private addSvgIcon(icon: string, path: string): void {
    this.matIconRegistry.addSvgIcon(
      icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path),
    );
  }

  public registerCustomIcons(): void {
    this.addSvgIcon('touch-id', this.loginPath + 'touch-id.svg');
    this.addSvgIcon('email', this.loginPath + 'email.svg');
    this.addSvgIcon('password', this.loginPath + 'password.svg');
    this.addSvgIcon('error', this.loginPath + 'error.svg');

    this.addSvgIcon('user', this.topBarPath + 'user.svg');
    this.addSvgIcon('currency', this.topBarPath + 'currency.svg');
    this.addSvgIcon('logout', this.topBarPath + 'logout.svg');
    this.addSvgIcon('en', this.topBarPath + 'en.svg');
    this.addSvgIcon('it', this.topBarPath + 'it.svg');
    this.addSvgIcon('arrow-down', this.topBarPath + 'arrow-down.svg');

    this.addSvgIcon('heart', this.campaignsPath + 'heart.svg');
    this.addSvgIcon('desc-arrows', this.campaignsPath + 'desc-arrows.svg');

    this.addSvgIcon('edit', this.profilePath + 'edit.svg');
    this.addSvgIcon('profile-password', this.profilePath + 'password.svg');
    this.addSvgIcon('arrow-back-white', this.profilePath + 'arrow-back.svg');

    this.addSvgIcon('fb', this.socPath + 'fb.svg');
    this.addSvgIcon('inst', this.socPath + 'inst.svg');
    this.addSvgIcon('reels', this.socPath + 'reels.svg');
    this.addSvgIcon('stories', this.socPath + 'stories.svg');
    this.addSvgIcon('linkedin', this.socPath + 'linkedin.svg');
    this.addSvgIcon('tiktok', this.socPath + 'tiktok.svg');
    this.addSvgIcon('youtube', this.socPath + 'youtube.svg');
    this.addSvgIcon('fb-white', this.socPath + 'fb-white.svg');
    this.addSvgIcon('inst-white', this.socPath + 'inst-white.svg');
    this.addSvgIcon('reels-white', this.socPath + 'reels-white.svg');
    this.addSvgIcon('stories-white', this.socPath + 'stories-white.svg');
    this.addSvgIcon('linkedin-white', this.socPath + 'linkedin-white.svg');
    this.addSvgIcon('tiktok-white', this.socPath + 'tiktok-white.svg');

    this.addSvgIcon('accepted', this.postsPath + 'accept.svg');
    this.addSvgIcon('declined', this.postsPath + 'decline.svg');
    this.addSvgIcon('approve', this.postsPath + 'approve.svg');
    this.addSvgIcon('change', this.postsPath + 'change.svg');
    this.addSvgIcon('decline-big', this.postsPath + 'decline-big.svg');
    this.addSvgIcon('eye-open', this.postsPath + 'eye-open.svg');
    this.addSvgIcon('ticket-star', this.postsPath + 'ticket-star.svg');
    this.addSvgIcon('play', this.postsPath + 'play.svg');
    this.addSvgIcon('danger', this.postsPath + 'danger.svg');
    this.addSvgIcon('refresh', this.postsPath + 'refresh.svg');
    this.addSvgIcon('unseen-post', this.postsPath + 'unseen-post.svg');

    this.addSvgIcon('choose-img', this.createCampaignPath + 'choose-img.svg');
    this.addSvgIcon('remove', this.createCampaignPath + 'remove.svg');
    this.addSvgIcon('tooltip', this.createCampaignPath + 'tooltip.svg');

    this.addSvgIcon('animals-interest', this.createCampaignInterestsPath + 'animals-interest.svg');
    this.addSvgIcon('books-interest', this.createCampaignInterestsPath + 'books-interest.svg');
    this.addSvgIcon('education-interest', this.createCampaignInterestsPath + 'education-interest.svg');
    this.addSvgIcon('family-interest', this.createCampaignInterestsPath + 'family-interest.svg');
    this.addSvgIcon('lifestyle-interest', this.createCampaignInterestsPath + 'lifestyle-interest.svg');
    this.addSvgIcon('music-interest', this.createCampaignInterestsPath + 'music-interest.svg');
    this.addSvgIcon('photo-interest', this.createCampaignInterestsPath + 'photo-interest.svg');
    this.addSvgIcon('shopping-interest', this.createCampaignInterestsPath + 'shopping-interest.svg');
    this.addSvgIcon('sport-interest', this.createCampaignInterestsPath + 'sport-interest.svg');
    this.addSvgIcon('travel-interest', this.createCampaignInterestsPath + 'travel-interest.svg');

    this.addSvgIcon('check', this.commonPath + 'check.svg');
    this.addSvgIcon('check-colored', this.commonPath + 'check-colored.svg');
    this.addSvgIcon('cancel', this.commonPath + 'cancel.svg');
    this.addSvgIcon('cancel-colored', this.commonPath + 'cancel-colored.svg');
    this.addSvgIcon('plus-green', this.commonPath + 'plus-green.svg');
    this.addSvgIcon('arrow-back', this.commonPath + 'arrow-back.svg');
    this.addSvgIcon('arrow-left', this.commonPath + 'arrow-left.svg');
    this.addSvgIcon('arrow-right', this.commonPath + 'arrow-right.svg');

    this.addSvgIcon('card', this.paypalDialogPath + 'card.svg');
  }
}
