export const environment = {
  production: false,
  baseUrl: 'https://api-dev.flytrendy.com/',
  S3_IMAGES: 'https://stg-images.flytrendy.com',
  S3_VIDEOS: 'https://stg-videos.flytrendy.com',

  // baseUrl: 'https://api.flytrendy.com/',
  // S3_IMAGES: 'https://images.flytrendy.com',
  // S3_VIDEOS: 'https://videos.flytrendy.com',
};
