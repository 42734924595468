<div class="app">
  @if (isAuth()) {
    <div class="top-bar-bg">
      <web-top-bar />
    </div>
  }
  <main>
    @if (resolveLoading()) {
      <div class="spinner__main-section">
        <mat-spinner />
      </div>
    }
    <router-outlet />
  </main>
  <div class="footer-bg">
    <web-footer />
  </div>
</div>
