import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { authStore } from '@web/store';
import { ResolverLoadingClass } from '@web/models';
import { FooterComponent, TopBarComponent } from '@web/common';

@Component({
  standalone: true,
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [ RouterModule, MatProgressSpinner, TopBarComponent, FooterComponent ],
})
export class AppComponent extends ResolverLoadingClass {
  private authStore = inject(authStore);
  public isAuth = this.authStore.isAuth;
}
