import { Route } from '@angular/router';
import { HomeComponent } from '@web/landing';
import {
  authGuard,
  campaignGuard,
  insightsResolver,
  campaignResolver,
  postResolver,
  campaignAnalyticsResolver,
} from '@web/services';

export const appRoutes: Route[] = [
  { path: '', component: HomeComponent, canActivate: [ authGuard(false) ] },
  {
    path: 'campaigns',
    loadComponent: () => import('@web/campaigns').then((m) => m.CampaignsComponent),
    canActivate: [ authGuard() ],
  },
  {
    path: 'profile',
    loadComponent: () => import('@web/profile').then((m) => m.ProfileComponent),
    canActivate: [ authGuard() ],
  },
  {
    path: 'community-insights',
    loadComponent: () => import('@web/community-insights').then((m) => m.CommunityInsightsComponent),
    canActivate: [ authGuard() ],
    data: {
      colorCharts: [ '#1BA5F2', '#1A3869' ],
      genderColors: [ '#FB2047', '#417FE6' ],
      ageColors: [ '#417FE6', '#2a5fb6', '#14428f', '#0d2d62' ],
    }, // TODO: Move to config
  },
  {
    path: 'campaigns/:id',
    resolve: { campaignLoaded: campaignResolver },
    canActivate: [ authGuard() ],
    children: [
      {
        path: '',
        loadComponent: () => import('@web/campaigns').then((m) => m.CampaignPageComponent),
      },
      {
        path: 'post/:id',
        loadComponent: () => import('@web/campaigns').then((m) => m.PostPageComponent),
        resolve: { postLoaded: postResolver },
      },
      {
        path: 'edit',
        loadComponent: () => import('@web/create-campaign').then((m) => m.CreateCampaignComponent),
        canDeactivate: [ campaignGuard ],
      },
      {
        path: 'analytics',
        loadComponent: () => import('@web/campaigns').then((m) => m.AnalyticsComponent),
        resolve: { analyticsLoaded: campaignAnalyticsResolver },
      },
    ],
  },
  {
    path: 'insights/:id',
    loadComponent: () => import('@web/campaigns').then((m) => m.InsightsComponent),
    resolve: { insightsLoaded: insightsResolver },
    data: {
      colorStart: '#1BA5F2',
      colorEnd: '#1A3869',
    },
    canActivate: [ authGuard() ],
  },
  {
    path: 'campaign',
    loadComponent: () => import('@web/create-campaign').then((m) => m.CreateCampaignComponent),
    canDeactivate: [ campaignGuard ],
    canActivate: [ authGuard() ],
  },
  {
    path: 'services/feedback/:id/:email',
    loadComponent: () => import('@web/common').then((m) => m.PostFeedbackComponent),
  },
  {
    path: 'login',
    loadComponent: () => import('@web/landing').then((m) => m.LoginComponent),
    canActivate: [ authGuard(false) ],
  },
  {
    path: 'activate', loadComponent: () => import('@web/landing').then((m) => m.ActivateInfluencerComponent),
  },
  {
    path: 'changepassword', loadComponent: () => import('@web/landing').then((m) => m.ChangePasswordComponent),
  },
  {
    path: 'delete-account', loadComponent: () => import('@web/landing').then((m) => m.DeleteAccountComponent),
  },
  { path: '**', loadComponent: () => import('@web/landing').then((m) => m.NotFoundComponent) },
];
