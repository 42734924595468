import { Provider } from '@angular/core';
import { FacebookLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';

export const fbAuthProvider: Provider = {
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: false,
    lang: 'en',
    providers: [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('1763144420366434'),
      },
    ],
    onError: (err) => {
      console.error(err);
    },
  } as SocialAuthServiceConfig,
};
